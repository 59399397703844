const Refund = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.66659 12.1666C9.49067 12.1666 10.2963 11.9222 10.9815 11.4644C11.6667 11.0065 12.2007 10.3558 12.5161 9.59444C12.8314 8.83308 12.914 7.9953 12.7532 7.18704C12.5924 6.37879 12.1956 5.63636 11.6129 5.05364C11.0301 4.47092 10.2877 4.07409 9.47946 3.91332C8.67121 3.75254 7.83343 3.83506 7.07207 4.15042C6.31071 4.46579 5.65997 4.99984 5.20213 5.68504C4.74429 6.37025 4.49992 7.17583 4.49992 7.99992V9.58992M5.66659 8.99992L4.66659 10.1666L3.33325 8.99992"
      stroke="#FFD2A8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0011 6.66602H8.00114C7.63295 6.66602 7.33447 6.96449 7.33447 7.33268C7.33447 7.70086 7.63295 7.99935 8.00114 7.99935H9.33447C9.70265 7.99935 10.0011 8.29784 10.0011 8.66602C10.0011 9.03419 9.70265 9.33268 9.33447 9.33268H7.33447"
      stroke="#FFD2A8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66772 10.6661L8.66772 5.33276"
      stroke="#FFD2A8"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Refund;
