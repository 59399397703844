import { useRouter } from 'next/router';
import classNames from 'classnames';
import Banner from '@/shared/components/Banner';
import { Navbar, type NavbarLink } from './Navbar';

export const Layout = ({
  children,
  className,
}: {
  children?: JSX.Element[] | JSX.Element | string;
  className?: string;
}): JSX.Element => {
  const router = useRouter();
  const selectedTab = router.asPath;
  const navLinks: NavbarLink[] = [
    { label: 'Auctions', href: '/' },
    { label: 'My Nodes', href: '/nodes' },
    {
      label: 'Block Explorer',
      href: process.env.NEXT_PUBLIC_EXPLORER_URL as string,
      externalLink: true,
    },
  ];
  const navbarProps = {
    selectedTab,
    navLinks,
  };

  return (
    <div className={classNames('flex min-h-screen flex-col items-center', className)}>
      <Banner app="STAKE" />
      <Navbar {...navbarProps} />
      <div className="flex h-5/6 w-full flex-1 flex-col p-5 px-8 2xl:container lg:pt-5">
        {children}
      </div>
    </div>
  );
};
