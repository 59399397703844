import { useEffect, useState } from 'react';
import { TextInput } from '@/shared/components';
import { WarningTriangleIcon } from '@/shared/icons/large';
import FlipAmount from '@/shared/utils/FlipAmount';
import { isChainflipSs58Address } from '@/shared/utils/helpers';
import { FundAccountModal, type FundAccountModalProps } from './FundAccountModal';

export const RegisterNodeModal = ({
  active,
  onClose,
}: Pick<FundAccountModalProps, 'active' | 'onClose'>): JSX.Element => {
  const [nodeAddress, setNodeAddress] = useState('');
  const [nodeAddressErrorMessage, setNodeAddressErrorMessage] = useState('');

  useEffect(() => {
    if (nodeAddress === '') {
      setNodeAddressErrorMessage('');
      return;
    }

    if (nodeAddress.substring(0, 2) === '0x' || !isChainflipSs58Address(nodeAddress)) {
      setNodeAddressErrorMessage('Please enter a valid Chainflip SS58 validator key');
      return;
    }

    setNodeAddressErrorMessage('');
  }, [nodeAddress]);

  return (
    <FundAccountModal
      type="register"
      active={active}
      onClose={onClose}
      buttonsDisabled={!nodeAddress || Boolean(nodeAddressErrorMessage)}
      nodeAddress={nodeAddress}
      nodeCurrentBalance={FlipAmount.ZERO}
      onFunded={() => {
        setNodeAddress('');
      }}
      displayWarning
    >
      <TextInput
        label="Validator Public Key (SS58)"
        placeholder="cFMsX21nWi8xSsnF7NCvGjDnCfWr9zG8pV4qs1ap7Z7nRzSD2"
        onChange={(e) => {
          setNodeAddress(e.target.value);
        }}
        type="text"
        value={nodeAddress}
        failure={nodeAddressErrorMessage.length > 0}
        message={nodeAddressErrorMessage}
      />
      <div className="flex w-full items-center rounded-alert bg-cf-orange-4 p-4 text-14 text-cf-orange-1">
        <WarningTriangleIcon width="18" height="18" className="text-cf-orange-1" />
        <div className="ml-2">
          Don&apos;t add funds for a validator your don&apos;t control or else your funds will be
          lost!
        </div>
      </div>
    </FundAccountModal>
  );
};
