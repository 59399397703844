import Link from 'next/link';
import classNames from 'classnames';
import { BaseNavbar } from '@/shared/components';
import { ConnectButton } from '@/shared/components/RainbowKit';

export type NavbarLink = { label: string; href: string; externalLink?: true };

export interface NavbarProps {
  navLinks: NavbarLink[];
  selectedTab: string;
}

const NavLinks = ({ navLinks, activeTab }: { navLinks: NavbarLink[]; activeTab: number }) => (
  <>
    {navLinks.map(({ label, externalLink, href }, index) => (
      <Link
        key={label}
        className={classNames(activeTab === index && 'text-cf-white')}
        target={externalLink ? '_blank' : undefined}
        href={href}
      >
        {label}
      </Link>
    ))}

    <div className="items-center space-x-10">
      <ConnectButton />
    </div>
  </>
);

export const Navbar = ({ selectedTab, navLinks }: NavbarProps): JSX.Element => {
  const activeTab = navLinks.findIndex((link) => link.href === selectedTab);

  return (
    <BaseNavbar
      logoHref="/auctions"
      desktopContent={<NavLinks navLinks={navLinks} activeTab={activeTab} />}
      mobileContent={<NavLinks navLinks={navLinks} activeTab={activeTab} />}
      className="border-b border-b-cf-gray-3"
    />
  );
};
