import type BigNumber from 'bignumber.js';
import classNames from 'classnames';

interface DiffLabelProps {
  percentage: BigNumber;
  className?: string;
}

export function DiffLabel({ percentage, className = 'text-10' }: DiffLabelProps): JSX.Element {
  let color = 'white';
  let prefix = '';

  if (percentage.gt(0)) {
    color = 'text-cf-green-1';
    prefix = '+';
  } else if (percentage.lt(0)) {
    color = 'text-cf-red-1';
  }
  return (
    <div className={classNames(color, className)}>{`(${prefix}${percentage.toFixed(2)}%)`}</div>
  );
}
