import { ArrowIcon, ScrollIcon, RouteIcon } from '@/shared/icons/large';
import { TokenAmount, chainflipAssetMap } from '@/shared/utils';
import { assetConstants, type ChainflipAsset, type ChainflipChain } from '@/shared/utils/chainflip';
import { TokenAmountWithChainByAsset } from './TokenWithChain';
import { type SwapRequestFragment } from '../graphql/generated/graphql';

type RouteStep = {
  inChain: ChainflipChain;
  outChain: ChainflipChain;
  inAsset: ChainflipAsset;
  outAsset: ChainflipAsset;
  inAmount: TokenAmount;
  outAmount: TokenAmount;
  displayUsdcChainLogo: boolean;
};

export type RouteTooltipField = 'sourceAsset' | 'destinationAsset' | 'executedSwaps';

const RouteTooltip = ({
  swapRequest,
  type,
}: {
  swapRequest: Pick<SwapRequestFragment, RouteTooltipField>;
  type: 'Route' | 'Order';
}) => {
  const sourceChain = assetConstants[swapRequest.sourceAsset].chain;
  const destinationChain = assetConstants[swapRequest.destinationAsset].chain;
  const inputAmount = TokenAmount.fromAsset(
    swapRequest.executedSwaps.aggregates?.sum?.swapInputAmount,
    swapRequest.sourceAsset,
  );
  const intermediateAmount = TokenAmount.fromAsset(
    swapRequest.executedSwaps.aggregates?.sum?.intermediateAmount,
    'Usdc',
  );
  const outputAmount = TokenAmount.fromAsset(
    swapRequest.executedSwaps.aggregates?.sum?.swapOutputAmount,
    swapRequest.destinationAsset,
  );

  const routeSteps = [] as RouteStep[];

  if (inputAmount?.gt(0) && intermediateAmount?.gt(0) && outputAmount) {
    routeSteps.push(
      {
        inChain: sourceChain,
        outChain: 'Ethereum',
        inAsset: swapRequest.sourceAsset,
        outAsset: 'Usdc',
        inAmount: inputAmount,
        outAmount: intermediateAmount,
        displayUsdcChainLogo: false,
      },
      {
        inChain: 'Ethereum',
        outChain: destinationChain,
        inAsset: 'Usdc',
        outAsset: swapRequest.destinationAsset,
        inAmount: intermediateAmount,
        outAmount: outputAmount,
        displayUsdcChainLogo: false,
      },
    );
  } else if (inputAmount?.gt(0) && outputAmount) {
    routeSteps.push({
      inChain: sourceChain,
      outChain: destinationChain,
      inAsset: swapRequest.sourceAsset,
      outAsset: swapRequest.destinationAsset,
      inAmount: inputAmount,
      outAmount: outputAmount,
      displayUsdcChainLogo: true,
    });
  }

  return (
    <div className="flex min-w-[280px] flex-col space-y-4 p-2">
      <div className="flex items-center space-x-1 text-cf-white">
        {type === 'Order' ? <ScrollIcon /> : <RouteIcon />}
        <div className="font-aeonikMedium text-16">{type} Details</div>
      </div>
      {routeSteps.map((step) => (
        <div key={step.inAsset} className="flex flex-col space-y-4">
          <div key={step.inAmount?.toFixedDisplay()} className="flex items-center space-x-2">
            <TokenAmountWithChainByAsset
              asset={step.inAsset}
              displayChainLogo={step.inAsset !== 'Usdc' || step.displayUsdcChainLogo}
              amount={step.inAmount?.toFixedDisplay()}
              amountClass="font-aeonikMono text-12 text-cf-white"
              size="small"
              compact
            />
            <ArrowIcon width={16} className="text-cf-light-2" />
            <TokenAmountWithChainByAsset
              asset={step.outAsset}
              displayChainLogo={step.outAsset !== 'Usdc' || step.displayUsdcChainLogo}
              amount={step.outAmount?.toFixedDisplay()}
              amountClass="font-aeonikMono text-12 text-cf-white"
              size="small"
              compact
            />
          </div>
          <div className="text-12 text-cf-light-3">
            <div>
              {`Swap ${step.inAmount?.toFixedDisplay()} ${
                chainflipAssetMap[step.inAsset].symbol
              } for ${step.outAmount?.toFixedDisplay()} ${
                chainflipAssetMap[step.outAsset].symbol
              } on Chainflip`}
            </div>
          </div>
        </div>
      ))}
      <div className="text-12 text-cf-light-3">
        {inputAmount?.gt(0) &&
          outputAmount?.gt(0) &&
          `Rate: 1 ${chainflipAssetMap[swapRequest.sourceAsset].symbol} ≈ ${outputAmount
            .ratio(inputAmount)
            .toFixed(8)} ${chainflipAssetMap[swapRequest.destinationAsset].symbol}`}
      </div>
    </div>
  );
};

export default RouteTooltip;
