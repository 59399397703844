export enum StakeEvents {
  ConnectWallet = 'connect_wallet',
  RedeemFunds = 'redeem_funds',
  AddFunds = 'add_funds',
  AddNode = 'add_node',
  ViewPreviousRedemptions = 'view_previous_redemptions',

  CtaRegisterApproveToken = 'cta_register_approve_token',
  CtaRegisterAddFunds = 'cta_register_add_funds',
  RegisterNodeSuccess = 'register_node_success',

  CtaIncreaseApproveToken = 'cta_increase_approve_token',
  CtaIncreaseAddFunds = 'cta_increase_add_funds',
  IncreaseFundsSuccess = 'increase_funds_success',

  CtaGenerateCliCommand = 'cta_generate_cli_command',
  CommandSuccess = 'command_success',
  CtaRedeem = 'cta_redeem',
  CtaRefund = 'cta_refund',
  RedeemSuccess = 'redeem_success',
}

export type StakeTrackEvents = {
  [StakeEvents.ConnectWallet]: {
    connectedWallet: string;
    walletProvider: string;
    path: string;
  };
  [StakeEvents.RedeemFunds]: {
    scAccount: string;
    path: string;
    position: string;
  };
  [StakeEvents.AddFunds]: {
    scAccount: string;
    path: string;
    position: string;
  };
  [StakeEvents.AddNode]: {
    path: string;
    position: string;
  };
  [StakeEvents.ViewPreviousRedemptions]: {
    path: string;
  };
  [StakeEvents.CtaRegisterApproveToken]: {
    connectedWallet: string;
    scAccount: string;
    path: string;
    amount: string;
  };
  [StakeEvents.CtaRegisterAddFunds]: {
    connectedWallet: string;
    scAccount: string;
    path: string;
    amount: string;
  };
  [StakeEvents.RegisterNodeSuccess]: {
    connectedWallet: string;
    scAccount: string;
    path: string;
    amount: string;
  };
  [StakeEvents.CtaIncreaseApproveToken]: {
    connectedWallet: string;
    scAccount: string;
    path: string;
    amount: string;
  };
  [StakeEvents.CtaIncreaseAddFunds]: {
    connectedWallet: string;
    scAccount: string;
    path: string;
    amount: string;
  };
  [StakeEvents.IncreaseFundsSuccess]: {
    connectedWallet: string;
    scAccount: string;
    path: string;
    amount: string;
  };
  [StakeEvents.CtaGenerateCliCommand]: {
    redeemAddress: string;
    connectedWallet: string;
    scAccount: string;
    path: string;
    position: string;
    redeemAmount: string;
  };
  [StakeEvents.CommandSuccess]: {
    executeStartTime: string;
    connectedWallet: string;
    scAccount: string;
    path: string;
    position: string;
    redeemAmount: string;
    redeemAddress: string;
  };
  [StakeEvents.CtaRedeem]: {
    expiryTime: string;
    connectedWallet: string;
    scAccount: string;
    path: string;
    position: string;
    redeemAmount: string;
    redeemAddress: string;
  };
  [StakeEvents.CtaRefund]: {
    expiryTime: string;
    connectedWallet: string;
    scAccount: string;
    path: string;
    position: string;
    redeemAmount: string;
    redeemAddress: string;
  };
  [StakeEvents.RedeemSuccess]: {
    connectedWallet: string;
    path: string;
    position: string;
    redeemAmount: string;
    redeemAddress: string;
  };
};
