export const CURRENT_WALLET_LOCALSTORAGE_KEY = 'cf-current-wallet';
export const DATETIME_FORMAT = 'MMMM d yyyy, h:mm:ss a';
export const NUMERAL_NUMBER_FORMAT = '0,0.[00000]';
export const REQUEST_TIMEOUT = 5000;
export const REQUESTS_UNTIL_FAILED = 5;
export const BALANCE_UPDATE_INTERVAL = 5000;

export const POLL_INTERVAL = 5000; // Poll interval in MS for apollo polling

// num decimal places which constitutes of 1 flip
// ERC20 standard -- wei https://github.com/OpenZeppelin/openzeppelin-contracts/blob/master/contracts/token/ERC20/ERC20.sol#L46
export const DECIMAL_PLACES_FLIP = 18;

// Fallback const network id -- rinkeby
export const FALLBACK_ETH_NETWORK_ID = 4;
