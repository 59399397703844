import { gql } from '@/shared/graphql/generated';

export const getAllValidatorsQuery = gql(/* GraphQL */ `
  query Validators {
    validators: allValidators {
      nodes {
        ...CacheValidator
      }
    }
  }
`);

export const getValidatorsByIdSs58Query = gql(/* GraphQL */ `
  query GetValidatorsByAccountIds($idSs58s: [String!]!) {
    validators: allValidators(filter: { idSs58: { in: $idSs58s } }) {
      nodes {
        ...CacheValidator
      }
    }
  }
`);

export const getCurrentEpochQuery = gql(/* GraphQL */ `
  query GetLatestAuthoritySet {
    currentAuthoritySet: allAuthorityMemberships(orderBy: EPOCH_ID_DESC, first: 1) {
      nodes {
        epochId
      }
    }
  }
`);

export const getFundedAccountsByFunderQuery = gql(/* GraphQL */ `
  query GetFundedAccountIdsByFunder($walletAddress: String!) {
    events: allAccountFundingEvents(
      filter: { fundedFunder: { equalToInsensitive: $walletAddress }, type: { equalTo: FUNDED } }
    ) {
      nodes {
        accountId
        account: accountByAccountId {
          idSs58
        }
      }
    }
  }
`);

export const getRedemptionsByAccountIdsQuery = gql(/* GraphQL */ `
  query GetRedemptionsByAccountIds($accountIds: [Int!]!) {
    events: allAccountFundingEvents(
      filter: { type: { equalTo: REDEEMED }, accountId: { in: $accountIds } }
    ) {
      nodes {
        ...RedeemedEvent
      }
    }
  }
`);

export const getAccountsRewardsQuery = gql(/* GraphQL */ `
  query GetAccountsRewards($idSs58s: [String!]!, $epochId: Int) {
    accounts: allAccounts(filter: { idSs58: { in: $idSs58s } }) {
      nodes {
        idSs58
        historicRewards: accountEpochBalanceChangesByAccountId(
          filter: { endOfEpochBalance: { isNull: false } }
        ) {
          aggregates {
            sum {
              startOfEpochBalance
              balanceChange
              endOfEpochBalance
            }
          }
        }
        currentRewards: accountEpochBalanceChangesByAccountId(
          filter: { epochId: { equalTo: $epochId } }
        ) {
          nodes {
            accountId
            startOfEpochBalance
            balanceChange
          }
        }
      }
    }
  }
`);

export const getAccountsSlashedQuery = gql(/* GraphQL */ `
  query GetAccountsSlashed($idSs58s: [String!], $epochId: Int) {
    accounts: allAccounts(filter: { idSs58: { in: $idSs58s } }) {
      nodes {
        idSs58
        slashedEvents: accountFundingEventsByAccountId(filter: { type: { equalTo: SLASHED } }) {
          aggregates {
            sum {
              amount
            }
          }
        }
        currentEpochslashedEvents: accountFundingEventsByAccountId(
          filter: { and: [{ type: { equalTo: SLASHED } }, { epochId: { equalTo: $epochId } }] }
        ) {
          aggregates {
            sum {
              amount
            }
          }
        }
      }
    }
  }
`);

export const getAccountFundingDatesByIdSs58Query = gql(/* GraphQL */ `
  query GetAllValidatorFundingDates($idSs58s: [String!]!) {
    accounts: allAccounts(filter: { idSs58: { in: $idSs58s } }) {
      nodes {
        idSs58
        firstFunding: accountFundingEventsByAccountId(orderBy: ID_ASC, first: 1) {
          nodes {
            event: eventByEventId {
              block: blockByBlockId {
                timestamp
                id
              }
            }
          }
        }
        latestFunding: accountFundingEventsByAccountId(orderBy: ID_DESC, first: 1) {
          nodes {
            event: eventByEventId {
              block: blockByBlockId {
                timestamp
                id
              }
            }
          }
        }
      }
    }
  }
`);

export const getValidatorCfeVersionQuery = gql(/* GraphQL */ `
  query GetValidatorCfeVersion($idSs58: String!) {
    account: accountByIdSs58(idSs58: $idSs58) {
      validator: validatorByAccountId {
        cfeVersionId
      }
    }
  }
`);
