import * as RadixCheckbox from '@radix-ui/react-checkbox';

export default function Checkbox({
  setChecked,
  checked,
  id,
}: {
  id?: string;
  setChecked: (value: boolean) => void;
  checked: boolean;
}) {
  return (
    <RadixCheckbox.Root
      id={id}
      checked={checked}
      onCheckedChange={(value) => setChecked(value === 'indeterminate' ? false : value)}
      className="flex h-4 w-4 min-w-[16px] items-center justify-center rounded-sm border border-cf-gray-5 bg-cf-gray-3-5 transition hover:bg-cf-gray-4"
    >
      <RadixCheckbox.Indicator>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 8.70562L6.11765 11.0586L12 4.94092"
            stroke="#46DA93"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
}
