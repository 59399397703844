export const ChainflipLogo = (props: Record<string, string>) => (
  <svg
    width="167"
    height="28"
    viewBox="0 0 167 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.95 21.867C50.3119 21.867 53.529 19.3171 53.529 15.0514V14.6463H48.5246V15.0514C48.5246 16.8625 47.6667 17.5775 45.0215 17.5775C41.7805 17.5775 40.8988 16.8149 40.8988 13.6454C40.8988 10.4759 41.7805 9.71334 45.0215 9.71334C47.6667 9.71334 48.5246 10.4283 48.5246 12.2394V12.6445H53.529V12.2394C53.529 7.97371 50.3119 5.42383 44.95 5.42383C39.3974 5.42383 36.1326 8.66479 36.1326 13.6454C36.1326 18.626 39.3974 21.867 44.95 21.867Z"
      fill="white"
    />
    <path
      d="M59.1895 21.6287V15.6233H66.6247V21.6287H71.1048V5.66213H66.6247V11.596H59.1895V5.66213H54.7094V21.6287H59.1895Z"
      fill="white"
    />
    <path
      d="M76.6108 21.6287L77.85 18.6737H85.4282L86.6435 21.6287H91.7909L84.8801 5.66213H78.4696L71.5826 21.6287H76.6108ZM80.4714 12.4062L81.4961 9.71334H81.8059L82.8545 12.4062L83.7838 14.6463H79.542L80.4714 12.4062Z"
      fill="white"
    />
    <path d="M96.7507 21.6287V5.66213H92.2705V21.6287H96.7507Z" fill="white" />
    <path
      d="M102.662 21.6287V15.385L102.471 12.3347H102.781L104.807 15.4804L109.525 21.6287H114.291V5.66213H109.811V11.8343L110.025 15.3135H109.716L107.38 11.6436L102.948 5.66213H98.1816V21.6287H102.662Z"
      fill="white"
    />
    <path
      d="M120.209 21.6287V16.0761H129.646V12.0726H120.209V9.68951H130.003V5.66213H115.729V21.6287H120.209Z"
      fill="white"
    />
    <path d="M144.884 21.6287V17.6013H135.662V5.66213H131.181V21.6287H144.884Z" fill="white" />
    <path d="M150.556 21.6287V5.66213H146.076V21.6287H150.556Z" fill="white" />
    <path
      d="M156.467 21.6287V17.6013H160.828C164.617 17.6013 167 15.4089 167 11.6198C167 7.83072 164.617 5.66213 160.828 5.66213H151.987V21.6287H156.467ZM160.327 9.68951C161.9 9.68951 162.544 9.99931 162.544 11.6198C162.544 13.2641 161.9 13.5739 160.327 13.5739H156.467V9.68951H160.327Z"
      fill="white"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M28.4134 0V12.0968C28.4134 14.2473 27.7329 16.3307 26.5048 18.0444C26.1231 18.582 25.6915 19.0692 25.2102 19.5229L25.177 19.5397H19.501V8.33334H14.2399H8.92901H8.4643H3.21979L3.28617 8.26614L7.73405 4.08266L9.37711 2.55377C11.2359 0.806452 13.5263 0 16.0489 0H28.4134Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M25.1771 19.5565L25.1605 19.5733L25.1439 19.5901L24.8451 19.8757L23.7166 20.9509L19.6172 24.7984L19.2189 25.168C17.3601 26.8985 14.9204 27.8394 12.4143 27.8394H6.10352e-05V22.4294L0.0166576 19.5397L0.0498508 15.6922C0.0664473 12.8864 1.21161 10.2319 3.23639 8.31653H8.48091H8.49751V19.5397H25.1771V19.5565Z"
        fill="url(#paint1_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="16.4126"
        y1="-1.19336"
        x2="14.7837"
        y2="29.5084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48EF8A" />
        <stop offset="0.5458" stopColor="#43B4A6" />
        <stop offset="0.6918" stopColor="#2D796F" />
        <stop offset="0.9662" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="13.338"
        y1="29.3624"
        x2="9.06192"
        y2="-2.09742"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5F96" />
        <stop offset="0.6103" stopColor="#FF33AF" />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="28.4133" height="27.8562" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
