import { useState } from 'react';
import { ethers } from 'ethers';

// A custom hook to allow us to get and set big numbers for input fields.
// For the sake of the input field: accepts and returns string values
// For the sake of actual use: Return a BN.js as wei for interaction with typechain
// Assumes 18 decimal pt per token (wei)
export const useBigNumber = (): [
  number: string,
  setNumberSafe: (val: string) => void,
  wei: bigint,
] => {
  const [number, setNumber] = useState<string>('');
  const [wei, setWei] = useState(0n);

  // Only allow numbers and decimals in our string. Only 18 decimal points
  const setNumberSafe = (val: string) => {
    setNumber(val || '');
    if (val !== '') {
      setWei(ethers.parseUnits(val, 18));
    } else {
      setWei(0n);
    }
  };

  return [number, setNumberSafe, wei];
};
