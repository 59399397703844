const AddIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 5.75V18.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.25 12H5.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default AddIcon;
